@import './inc/colors';
@import './inc/variables';

*, *::before, *::after {
	margin:0;
	padding:0;
	box-sizing: inherit;
	font-family: inherit;
}

body {
	box-sizing:border-box;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Open Sans', sans-serif;
	font-weight: $weight-light;
	font-size: 14pt;
	padding:20px 40px 20px;
	min-height:100vh;
	border-left:20px solid $bsuRed;
	box-shadow: inset 20px 0 20px rgba(black, .15);
}

main {
	padding:60px 0;
	max-width:1200px;
}

.page-footer {
	position: fixed;
	bottom:10px;
	right:10px;
	img {
		width:100px;
		height: auto;
		display: block;
	}
}

a {
	color: $bsuRed;
}

button, .button, input[type=submit] {
	background: none;
	color:black;
	border: 1px solid currentColor;
	padding:.25em 1em;
	font-size: .75rem;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	transition:all .2s;
	user-select: none;
	border-radius: 5px;
	text-align: center;
	&[disabled] {
		opacity:.5;
		cursor:not-allowed;
	}
	&:hover {
		background:$bsuRed;
		color:white;
		border-color:$bsuRed;
	}
	&.big {
		font-size: 1.25rem;
	}
	&.download {
		display: inline-flex;
		align-items: center;
		text-align: left;
		svg {
			height:25px;
			margin-right: .5em;
		}
	}
}

label {
	cursor: pointer;
}

ul {
	list-style:none;
}



.two-column {
	display: flex;
	justify-content: space-between;
	> * {
		flex: 0 0 45%;
	}
	@media (max-width:700px){
		display: block;
	}
}

fieldset {
	border:none;
	margin:2em 0;
}

::placeholder {
	font-style: italic;
}

h3 {
	font-weight: $weight-semibold;
}
h4 {
	color:$gray;
	font-weight: $weight-light;
	font-style: italic;
	font-size: 80%;
}

.user-list {
	margin:1em 0;
	column-count:2;
	column-gap: 100px;
	&.single {
		column-count: 1;
	}
	@media (max-width:1200px){
		column-count: 1;
	}
	>li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding:.5em 1em;
		margin:0 -1em;
		font-size: 12pt;
		break-inside: avoid-column;
		width:100%;
		&:hover {
			background:$lightGray;
		}
	}
}

.split-form {
	display: flex;
	input {
		flex:1 1;
	}
	.button {
		flex:0 0 auto;
		margin-left: .5em;
	}
}

.data {
	margin-right: 1em;
	flex:1 1;
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 1em;
	}
	li {
		margin-right: 2em;
		display: flex;
		align-items: center;
		line-height: 1.1;
		margin-bottom: .5em;
	}
	span {
		font-size: 18pt;
		margin-right: .15em;
	}
	&.small {
		span {
			font-size: 18pt;
			margin-right: 0;
			margin-left: .25em;
			font-weight: $weight-semibold;
		}
	}
	&.row {
		margin-right: 0;
		li {
			margin-right: 0;
			margin-bottom: 1em;
		}
	}
}

.sectioned-list {
	> li {
		margin-bottom: 100px;
	}
}

.red {
	color: $bsuRed;
}