@import '../inc/colors';
@import '../inc/variables';

.error {
	> * {
		margin-top: 1em;
	}
	.back {
		text-decoration: none;
		color:$gray;
		display:flex;
		align-items: center;
		margin-top: 2em;
		font-weight: $weight-semibold;
	}
	h2 {
		color: $gray;
	}
	svg {
		height:40px;
		margin-right: 1em;
		path {
			fill:$gray !important;
		}
	}
  input:not([type=checkbox]) {
    padding: 5px 10px;
    font-size: 14pt;
    display: block;
    width: 100%;
    flex: 1 1 100%;
  }

}