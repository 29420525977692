@import '../../inc/colors';
@import '../../inc/variables';

.challengeProgress {
	text-align: center;
	max-width: 300px;
	ul {
		display: flex;
		justify-content: space-between;
		&:not(:last-child){
			li:nth-child(5){
				border-right: 2px solid $gray;
				padding-right: 12px; //accounts for space taken up by the border due to box-sizing:border-box
			}
		}
		&:last-child {
			font-weight: $weight-semibold;
		}
	}
	li {
		flex:1 1 calc(100% / 7);
		padding:10px;
		box-sizing:content-box;
	}
	img {
		width:100%;
		max-width: 50px;
	}
}