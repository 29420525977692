$weight-light:400;
$weight-semibold:600;
$weight-extraBold:800;

%primary-heading {
	font-size: 2.5rem;
	font-weight: $weight-light;
}
%secondary-heading {
	font-size: .75rem;
	font-weight: $weight-extraBold;
	text-transform: uppercase;
}
%page-title {
	font-size: 4rem;
	line-height: 1.1;
	font-weight: $weight-extraBold;
}
%section-title {
	font-size: 28pt;
	font-weight: $weight-extraBold;
	color:$gray;
}
%label {
	font-size: 12pt;
	font-weight: $weight-semibold;
}