@import '../inc/colors';
@import '../inc/variables';

.challenge {

	label {
		@extend %label;
		display: block;
		margin-bottom: .25em;
	}

    input:not([type=checkbox]) {
      padding: 5px 10px;
      font-size: 14pt;
      display: block;
      width: 100%;
      flex: 1 1 100%;
    }

	input:not([type=checkbox]){
		margin-bottom: 1em;
	}

	p {
		font-style: italic;
		color:$gray;
		margin:.25em 0 1em;
		font-size: 12pt;
	}

	footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		a {
			margin-top: 1em;
			font-size: 12pt;
		}
	}

}