@import '../inc/colors';
@import '../inc/variables';

.participantHistory {
	header {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 2em;
		h1 {
			@extend %section-title;
			small {
				display: block;
				font-size: 18pt;
			}
		}
	}
}