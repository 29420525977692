@import '../../inc/colors';
@import '../../inc/variables';

.pageHeader {
	background:$gray;
	margin:-20px -40px 0;
	padding:20px 40px;
	color:white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow:0 3px 10px rgba(0,0,0,0.2);
	position: relative;
	z-index: 1;
	user-select: none;
	h1 {
		@extend %page-title;
	}
	a {
		color:white;
	}
	img {
		width:150px;
		margin-right: 20px;
	}
	.logo {
		display: flex;
		align-items: center;
		text-decoration: none;
		text-transform: uppercase;
		color:$lightGray;
		font-weight: $weight-semibold;
		opacity:.6;
		span {
			border-left: 2px solid white;
			padding-left: 20px;
		}
	}
	.backArrow {
		margin-right:.25em;
		opacity:.5;
		transition:opacity .3s;
		&:hover {
			opacity:1;
		}
		svg {
			height:40px;
		}
	}
	.sectionMenu {
		position: absolute;
		top:10px;
		right:10px;
		font-size: 9pt;
		display: flex;
		font-weight: $weight-semibold;
		line-height: 1.2;
		li + li {
			margin-left: .75em;
			padding-left: .75em;
			border-left: 1px solid rgba(white, .5);
		}
		a {
			text-decoration: none;
			opacity:.5;
			transition: opacity .3s;
			&:hover {
				opacity:.7;
			}
		}
		.active {
			opacity:.9;
			&:hover {
				opacity: .9;
			}
		}
	}
}
