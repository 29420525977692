@import '../../inc/colors';
@import '../../inc/variables';

.postWritingData{
  margin-top: 1em;
  h2 {
    font-size: 18pt;
    margin-top: .5em;
    padding-top: .5em;
    border-top: 1px solid $lightGray;
    font-weight: 600;
  }
  .columns {
    display: flex;
    justify-content: space-between;
  }
  .postWritingTable{
    margin-top: 1em;
    tr{
      td, th{
        border: none;
        text-align: center;
        border-spacing: 0px;
        padding: 5px;
        font-size: 14px;
      }

      td:nth-child(3){
        text-align: right;
      }

      button{
        background: white;
        &:hover{
          background: #BA0C2F;
          color: white;
        }
      }

    }
    tr:nth-child(even){
      background-color: #EEE;

    }

  }
  .react-clock{
    width: 100px;
    height: 100px;
  }

  form{
    position: relative;
    width: 490px;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    h4{
      margin-bottom:1em;
    }

    label{
      margin-bottom: .5em;
      display: block;
    }

    .columnWrapper {
      flex:0 0 45%;
      margin-top: 1em;
      input[type=number]{
        padding:5px;
        font-size: 14px;
        width:100%;
        text-align: right;
      }
    }

    input[type=submit]{
      margin-top: 10px;
      width: 70px;
      align-self: flex-end;
    }
  }


}