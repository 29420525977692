@import '../inc/colors';
@import '../inc/variables';

.participants {

  .emailListContainer {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $gray;
    display: flex;
    align-items: flex-start;
  }

  .emailList{
    width:400px;
    height:125px;
    padding:10px;
    font-size: 10px;
    margin:0 30px;
  }

  .copyEmails{
    align-self: flex-end;
  }
}

