@import '../inc/colors';
@import '../inc/variables';

.dashboard {
	> h2 {
		@extend %secondary-heading;
	}
	.activeChallenges, .upcomingChallenges, .pastChallenges {
		> label {
			@extend %label;
			display: block;
			color:$gray;
			border-bottom: 3px solid $bsuRed;
			padding:0 0 .25em 0;
			margin-bottom: 1em;
		}
		> ul > li {
			margin-bottom: 30px;
		}
	}
	.currentChallenges {
		display: flex;
		flex-wrap: wrap;
	}
	.upcomingChallenges {
		flex: 1 1;
		margin-right: 5%;
		@media (max-width:950px){
			flex:0 1 48%;
			margin-right: 0;
		}
		@media (max-width:700px){
			flex:0 0 100%;
		}
		> label {
			border-bottom-style: dashed;
		}
		+ .activeChallenges {
			flex: 0 0 30%;
			@media (max-width:950px){
				flex:0 1 48%;
			}
			@media (max-width:700px){
				flex:0 0 100%;
				margin-top: 30px;
			}
		}
	}
	.activeChallenges {
		flex: 0 0 100%;
		margin-left: auto;
		@media (max-width:950px){
			flex:0 1 48%;
		}
		@media (max-width:700px){
			flex:0 0 100%;
			margin-top: 30px;
		}
	}
	.pastChallenges {
		> label {
			opacity:.5;
			border-bottom-color: $gray;
		}
		> ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			> li {
				opacity:.35;
				flex:0 0 30%;
				transition: opacity .3s;
				&:nth-child(3n+2):last-child {
					margin-right: auto;
					margin-left: 5%;
					@media (max-width:950px){
						margin-right: 0;
						margin-left: 0;
					}
				}
				@media (max-width:950px){
					flex:0 0 48%;
				}
				@media (max-width:700px){
					flex:0 0 100%;
				}
				&:hover {
					opacity:1;
				}
			}
		}
	}
  input:not([type=checkbox]) {
    padding: 5px 10px;
    font-size: 14pt;
    display: block;
    width: 100%;
    flex: 1 1 100%;
  }
}