@import '../../inc/colors';
@import '../../inc/variables';

.achievementsList {
	border:1px solid $bsuRed;
	border-radius:5px;
	font-size: 10pt;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	max-height: 150px;
	li {
		padding:.5em 1em;
	}
  input:not([type=checkbox]) {
    padding: 5px 10px;
    font-size: 14pt;
    display: block;
    width: 100%;
    flex: 1 1 100%;
  }
}