@import '../inc/colors';
@import '../inc/variables';

.teams {
	.teamsList {
		> li {
			border-top: 1px solid silver;
			padding-top: 30px;
			+ li {
				margin-top:30px;
			}
			> div {
				padding: 0 30px;
				> section {
					flex:1 1 30%;
				}
				> ul {
					flex: 1 1 70%;
				}
			}
			> h2 {
				@extend %section-title;
				margin-bottom: .5em;
			}

			.dataContainer {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				ul {
					flex:0 0 auto;
				}
			}
			.teamMembers {
				margin:0 0 0 30px;
			}
		}
	}
  input:not([type=checkbox]) {
    padding: 5px 10px;
    font-size: 14pt;
    display: block;
    width: 100%;
    flex: 1 1 100%;
  }
}