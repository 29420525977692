@import '../../inc/colors';
@import '../../inc/variables';

.announcement {
	background:$lightGray;
	padding:20px 20px;
	margin:0 -40px;
	color:black;
	background-image: url(../../assets/icon-announcement.svg);
	background-size: auto 120px;
	background-position: -20px center;
	background-repeat: no-repeat;
	padding-left:130px;
	box-shadow:0 2px 8px rgba(0,0,0,0.1);
	border-bottom: 1px solid rgba($gray, .1);
	form {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	label {
		flex:0 0 100%;
		margin-bottom: .5em;
		font-size: 10pt;
		color:$gray;
	}
	.announcementField {
		flex: 1 1 auto;
		margin-right: 1em;
		width:auto;
	}
	button {
		flex:0 0 auto;
		margin-left: auto;
	}
	p {
		font-style: italic;
		margin:5px 0;
		font-size: 14pt;
	}
  input:not([type=checkbox]) {
    padding: 5px 10px;
    font-size: 14pt;
    display: block;
    width: 100%;
    flex: 1 1 100%;
  }
}
