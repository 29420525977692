@import '../../inc/colors';
@import '../../inc/variables';

.challenge {
	.timeline {
		font-style: italic;
		font-size: 10pt;
		margin-bottom: 1em;
	}
	h1 {
		font-size: 24pt;
		line-height: 1.1;
		margin-bottom: .1em;
	}
	footer {
		display: flex;
		flex-direction: column;
		a, button {
			+ a, + button {
				margin-top: 1em;
			}
		}
	}
	a {
		svg {
			* {
				transition: stroke .3s, fill .3s;
			}
		}
		&:hover svg {
			path, line {
				stroke:white !important;
			}
			polygon {
				fill:white !important;
			}
		}
	}
	.edit {
		padding:10px;
		svg {
			max-width: 75px;
			display: block;
			margin:0 auto 1em;
		}
	}
	.content {
		display: flex;
		justify-content: space-between;
	}

  input:not([type=checkbox]) {
    padding: 5px 10px;
    font-size: 14pt;
    display: block;
    width: 100%;
    flex: 1 1 100%;
  }
}