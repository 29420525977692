@import '../inc/colors';
@import '../inc/variables';

.people {
	.admins {
		padding-bottom: 60px;
		margin-bottom: 60px;
		border-bottom: 1px solid $gray;
		form {
			label {
				@extend %label;
			}
			input {
				margin-bottom: .5em;
			}
		}
	}
	.participants {

	}
  input:not([type=checkbox]) {
    padding: 5px 10px;
    font-size: 14pt;
    display: block;
    width: 100%;
    flex: 1 1 100%;
  }
}